import React from "react";
import Page from "../components/Page";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Page>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </Page>
  );
};

export default NotFound;
