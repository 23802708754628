import React from "react";
import Page from "../components/Page";

const Thanks = () => {
  return (
    <Page>
      <h1>You're One Step Closer to a World of Delicious Recipes</h1>
      <div className="thanks-chapter">
        <p>
          Congratulations! You're now officially part of The Food Mixer
          community. We're thrilled to have you join us on this culinary
          adventure. Get ready to unlock a world of delightful flavours and
          culinary inspiration, all at your fingertips.
        </p>
        <p>
          An email will find its way into your inbox the very moment The Food
          Mixer app is available on your preferred store.
        </p>
        <p>Bon Appétit!</p>
        <p>The Food Mixer team</p>
      </div>
    </Page>
  );
};

export default Thanks;
