import React from "react";
import MyCarousel from "./MyCarousel";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import supabase from "../supabaseClient";

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

const HeroCTA = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: async (values) => {
      const { error } = await supabase
        .from("waiting_list")
        .insert({ email_address: values.email }, { returning: "minimal" })
        .then(navigate("/thanks"));
    },
  });

  const navigate = useNavigate();

  return (
    <div className="heroCTA">
      <h2 className="intro">
        Calling all food enthusiasts! Get in on the recipe revolution. Join our
        waiting list and enjoy a seamless experience of uploading, searching,
        and planning meals like never before!
      </h2>
      <section id="hero">
        <div>
          <MyCarousel />
        </div>
        <div className="cta">
          <h2>Be the first to know when the app launches</h2>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="email address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <span>{formik.errors.email}</span>
              )}
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default HeroCTA;
