import React, { useState } from "react";
import Page from "../components/Page";

import supabase from "../supabaseClient";

const Update = () => {
  const [done, setDone] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const updatePassword = async () => {
    await supabase.auth.updateUser({ password: newPassword });
    const { data, error } = await supabase.auth
      .updateUser({
        password: newPassword,
      })
      .then(setDone(true));
  };

  return !done ? (
    <Page>
      <h1>Update your password</h1>
      <div className="updateForm">
        <input
          name="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
          placeholder="new password"
        />
        <button onClick={() => updatePassword()}>UPDATE PASSWORD</button>
      </div>
    </Page>
  ) : (
    <Page>
      <h1>Password reset complete!</h1>
      <div className="thanks">
        <p>You may now log into the app with your new password</p>
      </div>
    </Page>
  );
};

export default Update;
