import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import EmailConfirmation from "./pages/EmailConfirmation";
import Update from "./pages/Update";
import Recover from "./pages/Recover";
import RecoverThanks from "./pages/RecoverThanks";
import Thanks from "./pages/Thanks";
import NotFound from "./pages/NotFound";
import NavBar from "./components/NavBar";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <div className="mainContainer">
        <NavBar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/emailconfirmation" element={<EmailConfirmation />} />
          <Route path="/update" element={<Update />} />
          <Route path="/recover" element={<Recover />} />
          <Route path="/recoverthanks" element={<RecoverThanks />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
