import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";

import { EffectCoverflow, Autoplay } from "swiper";

import slide_image_1 from "../images/recipe 1.png";
import slide_image_2 from "../images/search.png";
import slide_image_3 from "../images/add recipe.png";
import slide_image_4 from "../images/cooking.png";
import slide_image_5 from "../images/recipe.png";
import slide_image_6 from "../images/meal plan and favourites.png";
import slide_image_7 from "../images/search filter.png";
import slide_image_8 from "../images/settings.png";
import slide_image_9 from "../images/meal plan mixer.png";

const MyCarousel = () => {
  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: -21,
        stretch: 110,
        depth: 900,
        modifier: 1.25,
      }}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      speed={1500}
      modules={[EffectCoverflow, Autoplay]}
      className="swiper_container"
    >
      <SwiperSlide>
        <img
          src={slide_image_1}
          alt="The recipe page for one of our many amazing meals"
        />
        <p>Hundres of amazing recipes</p>
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide_image_9} alt="The automatic meal planner" />
        <p>Automatically create perfect meal plans</p>
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide_image_2} alt="The search page for our app" />
        <p>Search the extensive library of recipes</p>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide_image_3}
          alt="A page for adding your own amazing recipes"
        />
        <p>Add your own amazing recipes!</p>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide_image_4}
          alt="A large-font page to make following a recipe easier while cooking"
        />
        <p>A super convenient view for cooking</p>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide_image_5}
          alt="A closer look at a recipe page, showing the ingredients and the steps required"
        />
        <p>Easy to follow steps with every ingredient required</p>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide_image_6}
          alt="Your profile page that contains your favourites, your meal plan and all the recipes you have added"
        />
        <p>Your meal plan and all of your favourites in one place</p>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide_image_7}
          alt="The search page including the filter buttons to narrow your search"
        />
        <p>Filter your search to find exactly what you need</p>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={slide_image_8}
          alt="The settings page that includes help, tutorials, a contact form and a way to change your password"
        />
        <p>Need help or want to talk to us? It's all available</p>
      </SwiperSlide>
    </Swiper>
  );
};

export default MyCarousel;
