import React from "react";
import { useInView } from "react-intersection-observer";

const ToggleView = ({ children, classNames = "" }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`${classNames} transition-div ${
        inView ? "transition-1" : "transition-0"
      }`}
    >
      {children}
    </div>
  );
};

export default ToggleView;
