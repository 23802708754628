import React from "react";
import Page from "../components/Page";

const RecoverThanks = () => {
  return (
    <Page>
      <h1>Recovery email sent</h1>
      <div className="thanks">
        <p>
          An email has been sent to the address associated with your account.
        </p>
        <p>Please follow the link and choose a new password.</p>
      </div>
    </Page>
  );
};

export default RecoverThanks;
