import React, { useState } from "react";
import Page from "../components/Page";
import { useNavigate } from "react-router-dom";

import supabase from "../supabaseClient";

const Recover = () => {
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();

  const recoverEmail = async () => {
    const { data, error } = await supabase.auth
      .resetPasswordForEmail(userEmail, {
        redirectTo: "https://thefoodmixer.com/update",
      })
      .then(navigate("/recoverthanks"));
  };
  return (
    <Page>
      <h1>Recover your password</h1>
      <div className="updateForm">
        <input
          name="userEmail"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          placeholder="email address"
        />
        <button onClick={() => recoverEmail()}>SEND RECOVERY EMAIL</button>
      </div>
    </Page>
  );
};

export default Recover;
