import React from "react";
import screenshot1 from "../images/add recipe.png";
import screenshot2 from "../images/meal plan mixer.png";
import screenshot3 from "../images/search.png";
import Page from "../components/Page";
import ToggleView from "../components/ToggleView";
import HeroCTA from "../components/HeroCTA";

const Home = () => {
  return (
    <Page>
      <HeroCTA />
      <hr />
      <section className="screenshots">
        <h1>Have a closer look!</h1>
        <ToggleView classNames="demo">
          <h1>1</h1>
          <img
            src={screenshot1}
            alt="A page for adding your own amazing recipes"
          />
          <p>Add your own amazing recipes</p>
        </ToggleView>
        <ToggleView classNames="demo">
          <h1>2</h1>
          <img
            src={screenshot2}
            alt="A page for automatically creating meal plans"
          />
          <p>Let the app create your meal plan</p>
        </ToggleView>
        <ToggleView classNames="demo">
          <h1>3</h1>
          <img
            src={screenshot3}
            alt="The search page showing the results of searching for chicken"
          />
          <p>Search the ever growing database</p>
        </ToggleView>
      </section>
    </Page>
  );
};

export default Home;
