import React from "react";
import Page from "../components/Page";

const EmailConfirmation = () => {
  return (
    <Page>
      <h1>Thanks for choosing The Food Mixer.</h1>
      <div className="thanks">
        <p>
          Your account has been verified and you can now start using the app.
        </p>
        <p>We hope you enjoy using The Food Mixer app.</p>
      </div>
    </Page>
  );
};

export default EmailConfirmation;
