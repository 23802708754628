import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../images/business-logo.png";
import { useScrollPosition } from "../hooks/useScrollPosition";

const Home = () => {
  const scrollPostition = useScrollPosition();

  return (
    <header className={scrollPostition > 0 ? "shrunk" : ""}>
      <div className="headContainer">
        <div className="logoContainer">
          <Link to="/">
            <img src={logoImage} alt="The blue squirrel logo" />
          </Link>
          <Link to="/">
            <h1>The Food Mixer</h1>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Home;
